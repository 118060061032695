import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader, HomeHeader, BannerCenter, BannerButton } from "../utils"
import lakeShore from "../images/bcg/clemson-restaurant-on-lake-hartwell.jpg"
import food from "../images/bcg/crab-cakes-red-wine.jpg"
import QuickInfo from "../components/HomePageComponents/QuickInfo"

const MenuPage = ({ location }) => (
  <Layout>
    <SEO
      title="Menu"
      img={food}
      url={location.href}
    />

    <div id="menu-links">
      <PageHeader img={lakeShore}>
        <BannerCenter title="The Menu" subtitle="Lakeside Dining & Takeout">
          <BannerButton>
            <a href="https://assets.ctfassets.net/j4osy1ytcga2/5nKp9X5zF9yc2GvfMyLpjo/fda7fd9b148becc30cc642517e02bbd5/Traditions-Easter-2025-Menu.pdf">Easter</a>
          </BannerButton>
          <br/>
          <BannerButton>
            <a href="https://assets.ctfassets.net/j4osy1ytcga2/4YPxDkagxgDUJMlVP4oxi8/c08929ef69aae4080cd35cfce10de490/TRADITIONS-Dinner-V4-4-2025.pdf">Dinner</a>
          </BannerButton>
          <br/>
          <BannerButton>
            <a href="https://assets.ctfassets.net/j4osy1ytcga2/4KrAFiahD2BbgDwvrU8yiR/60cf3ba3c5196ac3b2264db3f56a165c/TRADITIONS-Lunch-Revised-4-2025.pdf">Lunch</a>
          </BannerButton>
          <br/>
          <BannerButton>
            <a href="https://assets.ctfassets.net/j4osy1ytcga2/3uR04IkIiTYE1aATe2KrnA/6cc85d359e93f8e6979f9582169d2ff4/_TRADITIONS-Brunch-V3-2025.pdf">Saturday & Sunday Brunch</a>
          </BannerButton>
          <br/>
          <BannerButton>
            <a href="https://assets.ctfassets.net/j4osy1ytcga2/pHnGYpQ3f5mb3YbbSbxNm/1354a081441fb87928fdb311f7df0ae8/TRADITIONS-KIDS-Dinner-4-2025.pdf">Kids Menu</a>
          </BannerButton>
          <br/>
          <BannerButton>
            <a href="/bar-menu.pdf">Bar Menu</a>
          </BannerButton>
        </BannerCenter>
      </PageHeader>
      <QuickInfo></QuickInfo>
      <HomeHeader img={food}></HomeHeader>
    </div>
  </Layout>
)

export default MenuPage
